const loader = document.querySelector('#loader');
if (loader) {
    document.addEventListener("DOMContentLoaded", function () {
        loader.classList.remove('show');

        loader.addEventListener("transitionend", (e) => {
            loader.classList.add('hidden');
        });
        setTimeout(function(){
            loader.classList.add('hidden');
        }, 500);
    });
}