

const defaultConfigPristine = {
    // class of the parent element where the error/success class is added
    classTo: 'form-group',
    errorClass: 'has-danger',
    successClass: 'has-success',
    // class of the parent element where error text element is appended
    errorTextParent: 'form-group',
    // type of element to create for the error text
    errorTextTag: 'div',
    // class of the error text element
    errorTextClass: 'text-danger text-sm'
};
// Pristine.setLocale('fr')
// Pristine.addMessages('fr', {
//     required: 'Ce champ est obligatoire.',
//     minlength: 'Veuillez entrer au moins {minlength} caractères.',
//     maxlength: 'Veuillez entrer au maximum {maxlength} caractères.',
//     min: 'Veuillez entrer une valeur supérieure ou égale à {min}.',
//     max: 'Veuillez entrer une valeur inférieure ou égale à {max}.',
//     pattern: 'Le format de ce champ est invalide.',
//     email: 'Veuillez entrer une adresse e-mail valide.',
//     url: 'Veuillez entrer une URL valide.',
//     number: 'Veuillez entrer un nombre valide.',
//     date: 'Veuillez entrer une date valide.',
//     tel: 'Veuillez entrer un numéro de téléphone valide.',
//     alpha: 'Ce champ ne doit contenir que des lettres.',
//     alphanum: 'Ce champ ne doit contenir que des lettres et des chiffres.',
// });
// Error message is erase by data-pristine-<NAME>-message="My custom message"
Pristine.addValidator(
    'equals',
    function (val, otherFieldSelector) {
        var other = document.querySelector(otherFieldSelector);
        return other && ((!val && !other.value) || other.value === val);
    },
    'Les champs n\'ont pas la même valeur',
    1,
    false
);

Pristine.addValidator(
    'pattern',
    function (val, pattern) {
        // when comma "," is present in string -> bug so let do it like this 
        pattern = this.getAttribute('data-pristine-pattern');
        if (pattern == null) {
            pattern = this.getAttribute('pattern');
        }
        pattern = new RegExp(pattern);
            // pattern.test for test if match with regex
        if (pattern.test(val)){
            return true;
        }
        return false;
    },
    'Field do not match with pattern',
    1,
    false
);

Pristine.addValidator(
    'tel',
    function () {
        // this.__instance get intlTelInput instance  (is lib intl tel) 
        return this.__instance.isValidNumber();
    },
    'Le numéro de téléphone est incorrect',
    1,
    false
);

var pristine;
window.addEventListener("DOMContentLoaded", (e) => {
    var forms = document.querySelectorAll(".js-pristine-validator");
    if (forms) {
        
        // create the pristine instances
        forms.forEach(form => {
            form.__instancePristine = new Pristine(form, defaultConfigPristine);  
            form.addEventListener('submit', function (e) {
                // check if the form is valid
                // var valid = pristine.validate(); // returns true or false
                if (!form.__instancePristine.validate()) {
                    e.preventDefault();
                }
            });  
        });
    }
    // form.addEventListener('submit', function (e) {
    //    e.preventDefault();
       
    //    // check if the form is valid
    //    var valid = pristine.validate(); // returns true or false
  
    // });
    // console.log('test');
    
});