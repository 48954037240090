NotifPanier = (function () {
    const load = async () => {
        try {
            const resp = await fetch(Routing.generate('shop_cart_nbr_produit'));
            const data = await resp.json();
            
            const notifCart = document.querySelector('.notif-cart');
            if (data.success) {
                if (data.quantite > 0) {
                    data.quantite = data.quantite > 9 ? '9+' : data.quantite;
                    notifCart.innerHTML = data.quantite;
                    notifCart.classList.remove('hidden');
                    notifCart.classList.add('inline-flex');
                } else {
                    notifCart.innerHTML = '0';
                    notifCart.classList.add('hidden');
                    notifCart.classList.remove('inline-flex');
                }
            }
        } catch (error) {
        // console.error(error);
        }
    };
    return { load: load  };
})();
NotifPanier.load();